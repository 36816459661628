import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../images/Swift Care White.png";

const Blog4 = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
      }, []);
  return (
    <div style={{ marginTop: "80px" }}>
      <div className="floating-icons">
        <a href="tel:+919030820612" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919030820612")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i> +91 9030820612
          </button>
        </a>
        <a href="https://wa.me/+919030820612" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>

      <div className="Blogs_bg1">
        <h1>Revolutionize Your Approach to Medical Equipment & Services</h1>
      </div>

      <div className="container blogs_container1 mb-5">
        <h3>Best Private Ambulance Service Hyderabad</h3>
        <div className="d-flex">
          <p>
            <i class="ri-user-3-fill"></i>admin
          </p>
          <p>
            {" "}
            <i class="ri-calendar-schedule-fill"></i>22 Aug 2024
          </p>
        </div>

        <p>
          Call{" "}
          <a
            href="tel:+919030820612"
            onClick={() => (window.location.href = "tel:+919030820612")}
          >
            +91 9030820612
          </a>{" "}
          In critical moments when health and safety are on the line, having
          access to a reliable private ambulance service hyderabad can make all
          the difference.
        </p>

        <p>
          At Rapid Ambulance Service Hyderabad, we provide a comprehensive range
          of medical transportation options, ensuring that you and your loved
          ones receive the care you need when it matters most.
        </p>

        <h3 style={{ color: "#000" }}>Our Services</h3>

        <h5>ICU Ambulances in Hyderabad</h5>
        <p>
          For patients requiring intensive care during transit, our ICU
          ambulances are fully equipped with advanced medical equipment and
          staffed by experienced medical professionals. We ensure that critical
          patients receive the necessary monitoring and care throughout their
          journey to the hospital.
        </p>

        <h5>Oxygen Ambulance in Hyderabad:</h5>
        <p>
          In emergencies where patients need immediate oxygen support, our
          oxygen ambulances are ready to respond. These ambulances come equipped
          with oxygen cylinders and essential medical supplies, allowing for
          safe and efficient transport of patients with respiratory issues.
        </p>

        <h5>Ventilator Ambulances in Hyderabad</h5>
        <p>
          For patients who require ventilatory assistance, our ventilator
          ambulances are designed to provide a critical lifeline. Equipped with
          state-of-the-art ventilators and staffed by trained paramedics, we
          ensure that patients receive high-quality care during transportation.
        </p>

        <h5>Patient Transport Ambulances in Hyderabad</h5>
        <p>
          Not all medical transfers are emergencies. Our patient transport
          ambulances cater to non-urgent medical needs, such as hospital visits
          or routine check-ups. We provide comfortable and safe transportation
          for patients requiring mobility assistance.
        </p>

        <h5>Dead Body Transport Ambulances in Hyderabad</h5>
        <p>
          In times of loss, our dead body transport ambulances provide a
          dignified and respectful means of transporting deceased loved ones.
        </p>
        <p>
          Our vehicles are equipped to handle the necessary arrangements with
          sensitivity and professionalism.
        </p>

        <h5>Dead Body Air Transportation Service in Hyderabad</h5>
        <p>
          For long-distance transfers, we offer dead body air transportation
          services, ensuring that all logistics are handled seamlessly.{" "}
        </p>
        <p>
          This service allows families to transport their loved ones to any city
          in India with care and respect.
        </p>

        <h5>Why Choose Rapid Ambulance Service?</h5>
        <h6>Availability Anytime</h6>
        <p>
          We are available 24/7, ready to respond to your calls at **{" "}
          <a
            href="tel:+919030820612"
            onClick={() => (window.location.href = "tel:+919030820612")}
          >
            +91 9030820612
          </a>
          **. Whether it’s an emergency or a planned transfer, our team is here
          to assist you at any time.
        </p>
        <h6>Compassionate Care</h6>
        <p>
          Our trained staff understands the emotional weight of medical and
          transport needs. We prioritize compassionate care, ensuring that every
          situation is handled with the utmost sensitivity.
        </p>
        <h6>Fully Equipped Vehicles</h6>
        <p>
          Our ambulances are equipped with modern medical technology and
          supplies, ensuring that patients receive the best possible care during
          their journey.
        </p>
        <h6>Affordable Services</h6>
        <p>
          We believe in providing quality service at reasonable prices. Our
          transparent pricing structure ensures no hidden fees, allowing
          families to focus on what truly matters.
        </p>
        <h5>Contact Us Today</h5>
        <p>
          If you are in need of a reliable private ambulance service in
          Hyderabad, look no further. Call us at **
          <a
            href="tel:+919030820612"
            onClick={() => (window.location.href = "tel:+919030820612")}
          >
            +91 9030820612
          </a>
          ** for immediate assistance. At Rapid Ambulance Service, we are
          committed to providing top-quality medical transportation with
          compassion and care, ensuring that you and your loved ones receive the
          support you need when it matters most.
        </p>
      </div>

      <div className="footerSection ">
        <div className="container">
          <div className="row  firstFooterContainer ">
            <div className="firstCol  col-12">
              <div>
                <img
                  src={footerLogo}
                  className="pabTopLogo"
                  id="bottomPabLogo"
                  alt=""
                />
                <p className="footerText">
                  Our commitment to expert precision shines through, guiding you
                  from moments of emergency to a realm of dedicated care. Our
                  ambulance services embody the essence of Rapidness, ensuring
                  that with just a call, essential care is at your fingertips.
                  Recognizing that every second holds profound significance, we
                  unleash the power of urgent, professional care, understanding
                  that your well-being hinges on rapid intervention.
                </p>
              </div>
            </div>

            <div className="thirdCol  col-12 ">
              <h4 className="text-center">Services </h4>

              <div className="d-flex flex-wrap justify-content-around">
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/BLS-ambulance">
                    <span>Basic Life Support</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/ventilator-ambulance">
                    <span>Ventilator Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Air-ambulance">
                    <span>Air Ambulance Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/freezer-box">
                    <span> Freezer Box Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Embalming">
                    <span>Embalming</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            className="thick-horizontal-line mx-2 "
            style={{ height: "1px", backgroundColor: "white" }}
          ></div>

          <div className="container secondFooterContainer row ">
            <div className="text-white col-md-6  col-12 align-self-center mt-4">
              <i className="fa-solid fa-copyright"></i> Copyright RAPID
              Ambulance. All rights reserved.
            </div>

            <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
              <i className="fa-brands fa-linkedin mx-2 logos" id="linkedIn"></i>
              <i
                className="fa-brands fa-instagram mx-2 logos"
                id="instagram"
              ></i>
              <i className="fa-brands fa-facebook mx-2 logos" id="facebook"></i>
              <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog4;
