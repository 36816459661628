import "./App.css";
import React, { useState, useEffect } from "react";
import { GoogleApiWrapper } from "google-maps-react";

// import { GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import logo from "./images/Rapid Ambulance.png";
import googleConfig from "./utils/googleApikey";
import aed from "./images/AED.png";
import ac from "./images/Air conditioned.png";
import strecher from "./images/Stretcher.png";
import monitor from "./images/Multipara monitor.png";
import o2cylinder from "./images/Oxygen cylinder.png";
import system from "./images/Defillberator.png";
import strech from "./images/Auto loading stretcher.png";
import wheelchair from "./images/Portable suction kit.png";
import als from "./images/ALS.png";
import wheel from "./images/wheelchair.png";
import aid from "./images/aid.png";
import bls from "./images/BLS.png";
import pts from "./images/PTS.png";
import ventilator from "./images/ventilation.png";
import emergency from "./images/emergency-call.png";
import freezer from "./images/freezer.png";
import footerLogo from "./images/Swift Care White.png";
import AOS from "aos";
import "aos/dist/aos.css";
import callIcon from "./images/Gojo-Logo-unscreen.gif";
import checkmark from "./images/check-mark.png";
import ventilation from "./images/ventilator.jpg";
import blsoxygen from "./images/oxygenBLS.jpg";
import freezerbox from "./images/freezerBox.jpg";
import airAmbulance from "./images/airambulance.jpg";
import emblam from "./images/embalming.jpg";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import lights from "./images/lights.png";

function App(props) {
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [distanceResult, setDistanceResult] = useState("");
  const [ambulanceType, setAmbulanceType] = useState("PTS with Freezer Box");

  const [calLoading, setCalLoading] = useState(true);

  const [originTrue, setOriginTrue] = useState(false);
  const [destinationTrue, setDestinationTrue] = useState(false);

  const calculateDistance = () => {
    if (origin !== "" && destination !== "" && ambulanceType !== "") {
      setCalLoading(false);
      const service = new props.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: "DRIVING",
          // componentRestrictions: { country: "IN" },
          unitSystem: props.google.maps.UnitSystem.METRIC,
        },
        (response, status) => {
          if (status === "OK") {
            let distance = response.rows[0].elements[0].distance.value / 1000;
            const duration = response.rows[0].elements[0].duration.text;

            distance = distance * 2;
            let fixedPrice = 2000;
            let v1range2 = 45;
            let f1range2 = 2300;
            let v2range3 = 65;
            let f2range3 = 3500;

            let v1range4 = 11;
            let v2range4 = 5;
            let f1range4 = 3500;
            let f2range5 = 7000;

            let v5range3 = 5;
            let f1range3 = 7000;

            let v1range5 = 27;
            let v2rang5 = 35;

            let v0range1 = 20;
            let v0range2 = 10;
            let v0range3 = 3.5;

            let v0fixed = 1500;

            // Adjust pricing based on the selected ambulance type
            switch (ambulanceType) {
              case "PTS and BIG BLS":
                // v1range2 = v1range2 * 0.95;
                // f1range2 = f1range2 * 0.95;
                // v1range4 = v1range4 * 0.95;
                // f1range4 = f1range4 * 0.95;
                // v1range5 = v1range5 * 0.95;
                if (distance < 10) {
                  const estprice = distance + f1range2;
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                } else if (distance >= 10 && distance <= 50) {
                  const estprice = distance * v1range2 + f1range2;
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                } else if (distance >= 51 && distance <= 250) {
                  const estprice = distance * v1range4 + f1range4;
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                } else if (distance >= 251) {
                  const estprice = distance * v1range5 + v1range4;
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                }
                break;
              case "PTS with Freezer Box":
                v1range2 = v1range2 * 0.95;
                f1range2 = f1range2 * 0.95;
                v1range4 = v1range4 * 0.95;
                f1range4 = f1range4 * 0.95;
                v1range5 = v1range5 * 0.95;
                if (distance < 10) {
                  const estprice = distance + f1range2;

                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                } else if (distance >= 10 && distance <= 50) {
                  const estprice = distance * v1range2 + f1range2;
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                } else if (distance >= 51 && distance <= 250) {
                  const estprice = distance * v1range4 + f1range4;
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                } else if (distance >= 251) {
                  const estprice = distance * v1range5 + v1range4;
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                }
                break;
              case "BIG ALS":
                if (distance < 10) {
                  const estprice = distance + f1range4;
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                } else if (distance >= 10 && distance <= 50) {
                  const estprice = distance * v2range3 + f2range3;
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                } else if (distance >= 51 && distance <= 250) {
                  const estprice = distance * v5range3 + f1range3;
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                } else if (distance >= 251) {
                  const estprice = distance * v2rang5 + v5range3;
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                }
                break;
              case "BIG ALS WITH EMT":
                if (distance < 10) {
                  const estprice = distance + f1range4 + 5;

                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                } else if (distance >= 10 && distance <= 50) {
                  const estprice =
                    distance * v0range1 + (distance * v2range3 + f2range3);
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                } else if (distance >= 51 && distance <= 250) {
                  const estprice =
                    distance * v0range2 + (distance * v5range3 + f1range3);
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                } else if (distance >= 251) {
                  const estprice =
                    distance * v0range3 +
                    (distance * v2rang5 + v5range3) +
                    v0fixed;
                  setDistanceResult(
                    `Estimated Price: Rs ${estprice.toFixed(0)}`
                  );
                }
                break;
              default:
                break;
            }

            // let estimatedPrice = 0;

            // // Calculate estimated price for 0-9 kms range
            // if (distance <= 9) {
            // } else if (distance >= 10 && distance <= 50) {
            //   estimatedPrice = distance * v1range2 + f1range2;
            // } else {
            //   // Handle case where distance exceeds 9 kms
            //   // You may add additional logic here if needed
            //   // For now, set estimated price to 0 or display an error message
            //   estimatedPrice = 0;
            // }

            // setDistanceResult(`Estimated Price: Rs ${estimatedPrice.toFixed(0)}`);
          } else {
            alert("Error: " + status);
          }
        }
      );
      setCalLoading(true);
    } else {
      alert("select all fields");
    }
  };
  const [activeTab, setActiveTab] = useState("tab4");

  const handleTabClick = (tabId, type) => {
    setActiveTab(tabId);
    setAmbulanceType(type);
  };

  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  // const handleOriginSelect = async (address) => {
  //   setOrigin(address);
  // };
  const handleOriginSelect = (address) => {
    setOrigin(address);
    setOriginTrue(true);
    geocodeByAddress(address)
      .then((results) => {
        const country = results[0].address_components.find((component) =>
          component.types.includes("country")
        );
        if (country && country.short_name !== "IN") {
          alert("Please select an origin within India.");
          setOrigin("");
        }
      })
      .catch((error) => console.error("Error", error));
  };

  // const handleDestinationSelect = async (address) => {
  //   setDestination(address);
  // };
  const handleDestinationSelect = (address) => {
    setDestination(address);
    setDestinationTrue(true);
    geocodeByAddress(address)
      .then((results) => {
        const country = results[0].address_components.find((component) =>
          component.types.includes("country")
        );
        if (country && country.short_name !== "IN") {
          alert("Please select a destination within India.");
          setDestination("");
        }
      })
      .catch((error) => console.error("Error", error));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      calculateDistance();
    }
  };
  useEffect(() => {
    // Check if origin, destination, and ambulanceType are not empty strings
    {
      originTrue && destinationTrue && calculateDistance();
    }
  }, [ambulanceType]);

  const [selectedTab, setSelectedTab] = useState("tab-one");

  const handleTabClick1 = (tabId) => {
    // If the clicked tab is already selected, clear the selectedTab
    if (selectedTab === tabId) {
      setSelectedTab("");
    } else {
      setSelectedTab(tabId);
    }
  };

  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  // const handleBookAmbulance = () => { };
  const handleBookAmbulance = () => {
    // Create an object with the data to send
    const data = {
      name: fullName,
      number: mobileNumber,
    };
    if (fullName !== "" && mobileNumber !== "" && mobileNumber.length === 10) {
      // Your code here

      // Make a POST request
      fetch("https://rapidambulance-apis.onrender.com/alert", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      });
      toast.success("Thanks for reaching out !");
    } else {
      toast.error("Please fill all details");
    }
    // .then((data) => {
    //   console.log("Response:", data);
    //   // Handle success response here
    // })
    // .catch((error) => {
    //   console.error("There was a problem with the request:", error);
    //   // Handle error here
    // });
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
        theme="dark"
        className="custom-toast-container"
      />
      {/* <nav
        className="navbar navbar-expand-lg  fixed-top"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <div className="container align-items-center justify-content-center">
          <div>
            <img src={logo} alt="logo" className="img-fluid pabTopLogo" />
          </div>
        </div>
      </nav> */}
      {/* <Navbar /> */}
      {/* <div className="calculatedContainer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col_md_6_text d-flex flex-column  justify-content-center order-1 order-md-0">
              <div data-aos="fade-down" data-aos-duration="2000">
                <h2 className="">Emergency on wheels</h2>
              </div>
              <div data-aos="fade-up" data-aos-duration="2000">
                <p className="">
                  <span className="d-none d-md-block">
                    {" "}
                    Experience the assurance of our state-of-the-art ambulances
                    equipped with cutting-edge medical technology.
                  </span>{" "}
                  We provide swift transportation, ensuring that critical care
                  begins the moment we arrive
                </p>
              </div>
            </div>

            <div className="col-md-6  order-0 order-md-1">
              <div className="">
                <div data-aos="fade-up" data-aos-duration="2000">
                  <div className="tab-content col-12">
                    <div data-aos="fade-in" data-aos-duration="2000">
                      <div className="row">
                        <div className="col-1"></div>
                        <div className="col-lg-10 tabs_content_text  ">
                         

                          <div className="home_banner_img2">
                            <img
                              src="https://redhealth.gumlet.io/build/images/hero-mobile.webp?w=720&dpr=1.3"
                              className="img-fluid"
                            />
                          </div>

                          <div className="home_banner_form">
                            <h5>GET A CALL BACK IN JUST 5 SECONDS</h5>
                            <div>
                              <div className="">
                                <input
                                  className="form-control my-3"
                                  placeholder="Full Name"
                                  value={fullName}
                                  onChange={(e) => setFullName(e.target.value)}
                                />
                                <input
                                  className="form-control mt-3"
                                  placeholder="Mobile Number"
                                  value={mobileNumber}
                                  onChange={(e) =>
                                    setMobileNumber(e.target.value)
                                  }
                                />
                              </div>
                              <div>
                                <span>
                                  <button
                                    style={{ width: "fit-content" }}
                                    className="my-3 estimate_button mr-2"
                                    onClick={handleBookAmbulance}
                                  >
                                    Book Ambulance
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="home_for_large">
        <div className="new_home">
          <div className="container new_home_container">
            <div className="row">
              <div className="col-md-6 col_md_6_text d-flex flex-column justify-content-center order-2 order-md-0">
                <div
                  data-aos="fade-down"
                  data-aos-duration="2000"
                  className="order-1"
                >
                  <h2>
                    Your <span className="blink">lifeline</span> in critical
                    moments
                  </h2>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="order-3"
                >
                  <p>
                    Ambulance Service Hyderabad, ICU & Oxygen Ambulances,
                    Patient & Dead Body Transportation From Hyderabad To All
                    Over India. Local & Outstation Ambulances From Hyderabad.
                    Neat & Sanitized Ambulance. 24/7 Ambulance Support.
                  </p>

                  <p>
                    <a href="tel:+919030820612" className="icon">
                      <button
                        onClick={() =>
                          (window.location.href = "tel:+919030820612")
                        }
                        className="red_fixed_button_home"
                      >
                        call : 9030820612
                      </button>
                    </a>
                  </p>
                </div>
              </div>

              <div className="col-md-6 order-1 order-md-1">
                <div className="home_banner_form order-2 order-md-2">
                  <h5>GET A CALL BACK IN JUST 5 SECONDS</h5>
                  <div>
                    <div className="">
                      <input
                        className="form-control my-3"
                        placeholder="Full Name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                      <input
                        className="form-control mt-3"
                        placeholder="Mobile Number"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                      />
                    </div>
                    <div>
                      <span>
                        <button
                          style={{ width: "fit-content" }}
                          className="my-3 estimate_button mr-2"
                          onClick={handleBookAmbulance}
                        >
                          Book Ambulance
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home_for_mobile">
        <div className="new_home_mobile">
          <div className="container">
            <div className="row">
              <div className="col-md-6 d-flex flex-column justify-content-center order-2 order-md-0 col_md_6_text">
                <div
                  data-aos="fade-down"
                  data-aos-duration="2000"
                  className="order-1 col_md_6"
                >
                  <h2>
                    Your <span className="blink">lifeline</span> in critical
                    moments
                  </h2>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="order-2"
                >
                  <div className="home_banner_form mt-2">
                    <h5>GET A CALL BACK IN JUST 5 SECONDS</h5>
                    <div>
                      <div>
                        <input
                          className="form-control my-3"
                          placeholder="Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                        <input
                          className="form-control mt-3"
                          placeholder="Mobile Number"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                        />
                      </div>
                      <div>
                        <span>
                          <button
                            style={{ width: "fit-content" }}
                            className="my-3 estimate_button mr-2"
                            onClick={handleBookAmbulance}
                          >
                            Book Ambulance
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="order-3"
                >
                  <p>
                    where every journey begins with a promise of exceptional
                    care and compassion. Our dedicated team of medical
                    professionals is committed to providing rapid and reliable
                    ambulance services.
                  </p>
                </div>
              </div>

              <div className="col-md-6 order-1 order-md-1"></div>
            </div>
          </div>
        </div>
      </div>

      <div data-aos="fade-in" data-aos-duration="2000">
        <div>
          <div className="text-center mt-5">
            <h2 className="technologiesHeading">
              {" "}
              Behind the Scenes of Our Life-Saving Missions
            </h2>
          </div>

          <div className="text-center container my-5  ">
            <p className="techonologiesText">
              Navigating Emergencies with Expert Precision, Rapid Response,
              Expert Care, Your Lifeline in Critical Moments. Rapid and safe
              transportation of patients to hospitals or healthcare facilities.
              Administration of advanced medical interventions such as
              intravenous medications, intubation, and cardiac monitoring.Safe
              transportation of pregnant women experiencing complications to
              maternity facilities.
            </p>
          </div>
        </div>
      </div>
      {/* <div style={{ backgroundColor: "#fff" }}>
        <section className="container-md">
          <div className="tabs ">
            <div className="row">
              <div className="col-12 col-sm-2 mt-0 mt-sm-3">
                <ul className="tab-links d-flex flex-wrap flex-sm-column  justify-content-center">
                  <li className={activeTab === "tab4" ? "active" : ""}>
                    <a onClick={() => handleTabClick("tab4")}>PTS</a>
                  </li>
                  <li className={activeTab === "tab3" ? "active" : ""}>
                    <a onClick={() => handleTabClick("tab3")}>BLS</a>
                  </li>

                  <li className={activeTab === "tab1" ? "active" : ""}>
                    <a onClick={() => handleTabClick("tab1")}>ALS</a>
                  </li>
                </ul>
              </div>

              <div className="tab-content col-sm-10">
                <div
                  id="tab1"
                  className={`tab ${activeTab === "tab1" ? "active" : ""}  `}
                >
                  <div data-aos="fade-in" data-aos-duration="2000">
                    <div className="row">
                      <div className="col-lg-8 tabs_content_text">
                        <h2>Advance Life Support</h2>
                        <p>
                          Advanced Life Support is used for critical patients.
                          The Ambulance consists of an array of ICU equipment.
                          It has an in-built ventilator with oxygen cylinder, BP
                          monitor, and specialized medication required.
                        </p>
                        <div className="d-flex  flex-wrap tab-content-images">
                          <img src={aed} alt="" className="img-fluid m-2" />
                          <img src={ac} alt="" className="img-fluid m-2" />
                          <img
                            src={strecher}
                            alt=""
                            className="img-fluid m-2"
                          />
                          <img src={monitor} alt="" className="img-fluid m-2" />

                          <img
                            src={o2cylinder}
                            alt=""
                            className="img-fluid m-2"
                          />
                          <img src={system} alt="" className="img-fluid m-2" />
                          <img src={strech} alt="" className="img-fluid m-2" />
                          <img
                            src={wheelchair}
                            alt=""
                            className="img-fluid m-2"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 individual_iamge d-flex justify-content-center align-items-center">
                        <img src={als} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="tab3"
                  className={`tab ${activeTab === "tab3" ? "active" : ""}`}
                >
                  <div className="row">
                    <div className="col-lg-8 tabs_content_text">
                      <h2>Basic Life Support</h2>
                      <p>
                        Basic Life Support (BLS) is provided through a large van
                        equipped with essential medical tools, primarily for
                        non-critical patients and shorter transportation needs.
                      </p>
                      <div className="d-flex  flex-wrap tab-content-images">
                        <img src={strecher} alt="" className="img-fluid m-2" />
                        <img src={monitor} alt="" className="img-fluid m-2" />
                        <img src={aid} alt="" className="img-fluid m-2" />
                        <img src={wheel} alt="" className="img-fluid m-2" />
                        <img
                          src={o2cylinder}
                          alt=""
                          className="img-fluid m-2"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 individual_iamge d-flex justify-content-center align-items-center">
                      <img src={bls} alt="" />
                    </div>
                  </div>
                </div>

                <div
                  id="tab4"
                  className={`tab ${activeTab === "tab4" ? "active" : ""}`}
                >
                  <div className="row">
                    <div className="col-lg-8 tabs_content_text">
                      <h2>Patient Transport Service</h2>
                      <p>
                        The Patient Transport Service vehicle is utilized for
                        transferring non-critical patients between locations.
                        Equipped with a stretcher, it ensures safe and
                        comfortable transportation for those requiring medical
                        assistance.
                      </p>
                      <div className="d-flex  flex-wrap tab-content-images">
                        <img src={strecher} alt="" className="img-fluid m-2" />
                        <img src={monitor} alt="" className="img-fluid m-2" />
                        <img src={aid} alt="" className="img-fluid m-2" />
                        <img src={wheel} alt="" className="img-fluid m-2" />
                        <img
                          src={o2cylinder}
                          alt=""
                          className="img-fluid m-2"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 individual_iamge d-flex justify-content-center align-items-center">
                      <img src={pts} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div> */}

      <div
        style={{
          backgroundColor: "#fdf1f4",
          paddingTop: "70px",
          paddingBottom: "70px",
        }}
      >
        <div className="container">
          <section className="container">
            <div
              id="demo"
              className="carousel slide"
              data-ride="carousel"
              data-pause="true"
            >
              <ul className="carousel-indicators">
                <li
                  data-target="#demo"
                  data-slide-to="0"
                  className="active"
                ></li>
                <li data-target="#demo" data-slide-to="1"></li>
                <li data-target="#demo" data-slide-to="2"></li>
              </ul>

              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-lg-8 tabs_content_text m-auto">
                      <h2>Advance Life Support</h2>
                      <p>
                        Advanced Life Support is used for critical patients. The
                        Ambulance consists of an array of ICU equipment. It has
                        an in-built ventilator with oxygen cylinder, BP monitor,
                        and specialized medication required.
                      </p>
                      <div className="d-flex  flex-wrap tab-content-images">
                        <img src={aed} alt="" className="img-fluid m-2" />
                        <img src={ac} alt="" className="img-fluid m-2" />
                        <img src={strecher} alt="" className="img-fluid m-2" />
                        <img src={monitor} alt="" className="img-fluid m-2" />
                        <img
                          src={o2cylinder}
                          alt=""
                          className="img-fluid m-2"
                        />
                        <img src={system} alt="" className="img-fluid m-2" />
                        <img src={strech} alt="" className="img-fluid m-2" />
                        <img
                          src={wheelchair}
                          alt=""
                          className="img-fluid m-2"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 individual_iamge d-flex justify-content-center align-items-center">
                      <img src={als} alt="" />
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="row">
                    <div className="col-lg-8 tabs_content_text m-auto">
                      <h2>Basic Life Support</h2>
                      <p>
                        Basic Life Support (BLS) is provided through a large van
                        equipped with essential medical tools, primarily for
                        non-critical patients and shorter transportation needs.
                      </p>
                      <div className="d-flex  flex-wrap tab-content-images">
                        <img src={strecher} alt="" className="img-fluid m-2" />
                        <img src={monitor} alt="" className="img-fluid m-2" />
                        <img src={aid} alt="" className="img-fluid m-2" />
                        <img src={wheel} alt="" className="img-fluid m-2" />
                        <img
                          src={o2cylinder}
                          alt=""
                          className="img-fluid m-2"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 individual_iamge d-flex justify-content-center align-items-center">
                      <img src={bls} alt="" />
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="row">
                    <div className="col-lg-8 tabs_content_text m-auto">
                      <h2>Patient Transport Service</h2>
                      <p>
                        The Patient Transport Service vehicle is utilized for
                        transferring non-critical patients between locations.
                        Equipped with a stretcher, it ensures safe and
                        comfortable transportation for those requiring medical
                        assistance.
                      </p>
                      <div className="d-flex  flex-wrap tab-content-images">
                        <img src={strecher} alt="" className="img-fluid m-2" />
                        <img src={monitor} alt="" className="img-fluid m-2" />
                        <img src={aid} alt="" className="img-fluid m-2" />
                        <img src={wheel} alt="" className="img-fluid m-2" />
                        <img
                          src={o2cylinder}
                          alt=""
                          className="img-fluid m-2"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 individual_iamge d-flex justify-content-center align-items-center">
                      <img src={pts} alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <a
                className="carousel-control-prev"
                href="#demo"
                data-slide="prev"
              >
                <span className="carousel-control-prev-icon"></span>
              </a>
              <a
                className="carousel-control-next"
                href="#demo"
                data-slide="next"
              >
                <span className="carousel-control-next-icon"></span>
              </a>
            </div>
          </section>
        </div>
      </div>

      <div className="container">
        <div className="col-12 p-0" style={{ backgroundColor: "#fff" }}>
          <section className="">
            <div className="tabs">
              <div className="row">
                <div className="col-md-7 m-auto">
                  <div className="">
                    <div data-aos="fade-in" data-aos-duration="2000">
                      {/* <div
                        className="col-md-12 mt-0 mt-sm-3"
                        style={{ padding: "15px 0px" }}
                      >
                        <ul
                          className="tab-links d-flex flex-wrap justify-content-center"
                          style={{ marginBottom: "0px" }}
                        >
                          <li className={activeTab === "tab4" ? "active" : ""}>
                            <a
                              onClick={() =>
                                handleTabClick("tab4", "PTS with Freezer Box")
                              }
                            >
                              PTS{" "}
                              {activeTab === "tab4" && (
                                <img
                                  src={checkmark}
                                  style={{ width: "13px" }}
                                />
                              )}
                            </a>
                          </li>
                          <li className={activeTab === "tab3" ? "active" : ""}>
                            <a
                              onClick={() =>
                                handleTabClick("tab3", "PTS and BIG BLS")
                              }
                            >
                              BLS{" "}
                              {activeTab === "tab3" && (
                                <img
                                  src={checkmark}
                                  style={{ width: "13px" }}
                                />
                              )}
                            </a>
                          </li>

                          <li className={activeTab === "tab1" ? "active" : ""}>
                            <a
                              onClick={() => handleTabClick("tab1", "BIG ALS")}
                            >
                              ALS{" "}
                              {activeTab === "tab1" && (
                                <img
                                  src={checkmark}
                                  style={{ width: "13px" }}
                                />
                              )}
                            </a>
                          </li>
                          
                        </ul>
                      </div> */}
                    </div>
                    <div data-aos="fade-up" data-aos-duration="2000">
                      <div className="tab-content col-12">
                        <div
                          id="tab1"
                          className={`tab ${
                            activeTab === "tab1" ? "active" : ""
                          }  `}
                        >
                          <div data-aos="fade-in" data-aos-duration="2000">
                            <div className="row">
                              <div className="col-1"></div>
                              <div className="col-lg-10 tabs_content_text m-auto">
                                <h2> Ventilator Ambulance</h2>
                                <div>
                                  <div className="">
                                    <PlacesAutocomplete
                                      value={origin}
                                      onChange={setOrigin}
                                      onSelect={handleOriginSelect}
                                    >
                                      {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                      }) => (
                                        <div
                                          style={{ position: "relative" }}
                                          className="w-100"
                                        >
                                          <input
                                            required
                                            {...getInputProps({
                                              placeholder: "From",
                                              className: "form-control mt-3",
                                              onKeyPress: handleKeyPress,
                                            })}
                                          />
                                          <div
                                            className="suggestion-container"
                                            style={{
                                              display: suggestions.length
                                                ? "block"
                                                : "none",
                                            }}
                                          >
                                            {loading && (
                                              <div className="text-center">
                                                Loading...
                                              </div>
                                            )}
                                            {suggestions.map((suggestion) => (
                                              <div
                                                {...getSuggestionItemProps(
                                                  suggestion,
                                                  {
                                                    className:
                                                      "suggestion-item",
                                                  }
                                                )}
                                              >
                                                {suggestion.description}
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      )}
                                    </PlacesAutocomplete>

                                    <PlacesAutocomplete
                                      value={destination}
                                      onChange={setDestination}
                                      onSelect={handleDestinationSelect}
                                    >
                                      {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                      }) => (
                                        <div
                                          style={{ position: "relative" }}
                                          className="w-100 "
                                        >
                                          <input
                                            required
                                            {...getInputProps({
                                              placeholder: "To",
                                              className: "form-control mt-3",
                                              onKeyPress: handleKeyPress,
                                            })}
                                          />
                                          <div
                                            className="suggestion-container"
                                            style={{
                                              display: suggestions.length
                                                ? "block"
                                                : "none",
                                            }}
                                          >
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion) => (
                                              <div
                                                {...getSuggestionItemProps(
                                                  suggestion,
                                                  {
                                                    className:
                                                      "suggestion-item",
                                                  }
                                                )}
                                              >
                                                {suggestion.description}
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      )}
                                    </PlacesAutocomplete>
                                  </div>
                                  <div>
                                    <span>
                                      <button
                                        onClick={calculateDistance}
                                        style={{ width: "fit-content" }}
                                        className="my-3 estimate_button mr-2  "
                                      >
                                        Estimate
                                      </button>
                                      <span style={{ fontWeight: "600" }}>
                                        {" "}
                                        {calLoading
                                          ? distanceResult
                                          : "Calculating..."}
                                      </span>
                                    </span>
                                  </div>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                    }}
                                  >
                                    {" "}
                                    <span style={{ fontWeight: "600" }}>
                                      Note :{" "}
                                    </span>
                                    The prices provided herein are estimates and
                                    should be considered as such. They do not
                                    necessarily reflect the final or original
                                    prices of the products or services mentioned
                                  </p>
                                </div>
                                <iframe
                                  id="map-iframe"
                                  // width="auto"
                                  style={{ width: "100%" }}
                                  height="400"
                                  className="d-md-none mt-3"
                                  frameborder="0"
                                  src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyDrmfNuPxqcip7_nE3dVNas9d3bNgNivlQ&origin=${
                                    origin || "Hyderabad"
                                  }&destination=${destination || "Hyderabad"}`}
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="tab2"
                          className={`tab ${
                            activeTab === "tab2" ? "active" : ""
                          }`}
                        >
                          <div className="row">
                            <div className="col-1"></div>
                            <div className="col-lg-10 tabs_content_text">
                              <h2>ALS With Technician</h2>

                              <div>
                                <div className="">
                                  <PlacesAutocomplete
                                    value={origin}
                                    onChange={setOrigin}
                                    onSelect={handleOriginSelect}
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading,
                                    }) => (
                                      <div
                                        style={{ position: "relative" }}
                                        className="w-100"
                                      >
                                        <input
                                          required
                                          {...getInputProps({
                                            placeholder: "From",
                                            className: "form-control mt-3",
                                            onKeyPress: handleKeyPress,
                                          })}
                                        />
                                        <div
                                          className="suggestion-container"
                                          style={{
                                            display: suggestions.length
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          {loading && (
                                            <div className="text-center">
                                              Loading...
                                            </div>
                                          )}
                                          {suggestions.map((suggestion) => (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className: "suggestion-item",
                                                }
                                              )}
                                            >
                                              {suggestion.description}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>

                                  <PlacesAutocomplete
                                    value={destination}
                                    onChange={setDestination}
                                    onSelect={handleDestinationSelect}
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading,
                                    }) => (
                                      <div
                                        style={{ position: "relative" }}
                                        className="w-100 "
                                      >
                                        <input
                                          required
                                          {...getInputProps({
                                            placeholder: "To",
                                            className: "form-control mt-3",
                                            onKeyPress: handleKeyPress,
                                          })}
                                        />
                                        <div
                                          className="suggestion-container"
                                          style={{
                                            display: suggestions.length
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map((suggestion) => (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className: "suggestion-item",
                                                }
                                              )}
                                            >
                                              {suggestion.description}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                                </div>
                                <div>
                                  <span>
                                    <button
                                      onClick={calculateDistance}
                                      style={{ width: "fit-content" }}
                                      className="my-3 estimate_button mr-2  "
                                    >
                                      Estimate
                                    </button>
                                    <span style={{ fontWeight: "600" }}>
                                      {" "}
                                      {calLoading
                                        ? distanceResult
                                        : "Calculating..."}
                                    </span>
                                  </span>
                                </div>
                                <p
                                  style={{
                                    fontSize: "10px",
                                  }}
                                >
                                  {" "}
                                  <span style={{ fontWeight: "600" }}>
                                    Note :{" "}
                                  </span>
                                  The prices provided herein are estimates and
                                  should be considered as such. They do not
                                  necessarily reflect the final or original
                                  prices of the products or services mentioned
                                </p>
                              </div>
                              <iframe
                                id="map-iframe"
                                // width="auto"
                                style={{ width: "100%" }}
                                height="400"
                                className="d-md-none mt-3"
                                frameborder="0"
                                src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyDrmfNuPxqcip7_nE3dVNas9d3bNgNivlQ&origin=${
                                  origin || "Hyderabad"
                                }&destination=${destination || "Hyderabad"}`}
                              ></iframe>
                            </div>
                          </div>
                        </div>
                        <div
                          id="tab3"
                          className={`tab ${
                            activeTab === "tab3" ? "active" : ""
                          }`}
                        >
                          <div className="row">
                            <div className="col-1"></div>
                            <div className="col-lg-10 tabs_content_text">
                              <h2>Oxygen Support Ambulance</h2>

                              <div>
                                <div className="">
                                  <PlacesAutocomplete
                                    value={origin}
                                    onChange={setOrigin}
                                    onSelect={handleOriginSelect}
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading,
                                    }) => (
                                      <div
                                        style={{ position: "relative" }}
                                        className="w-100"
                                      >
                                        <input
                                          required
                                          {...getInputProps({
                                            placeholder: "From",
                                            className: "form-control mt-3",
                                            onKeyPress: handleKeyPress,
                                          })}
                                        />
                                        <div
                                          className="suggestion-container"
                                          style={{
                                            display: suggestions.length
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          {loading && (
                                            <div className="text-center">
                                              Loading...
                                            </div>
                                          )}
                                          {suggestions.map((suggestion) => (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className: "suggestion-item",
                                                }
                                              )}
                                            >
                                              {suggestion.description}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>

                                  <PlacesAutocomplete
                                    value={destination}
                                    onChange={setDestination}
                                    onSelect={handleDestinationSelect}
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading,
                                    }) => (
                                      <div
                                        style={{ position: "relative" }}
                                        className="w-100 "
                                      >
                                        <input
                                          required
                                          {...getInputProps({
                                            placeholder: "To",
                                            className: "form-control mt-3",
                                            onKeyPress: handleKeyPress,
                                          })}
                                        />
                                        <div
                                          className="suggestion-container"
                                          style={{
                                            display: suggestions.length
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map((suggestion) => (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className: "suggestion-item",
                                                }
                                              )}
                                            >
                                              {suggestion.description}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                                </div>
                                <div>
                                  <span>
                                    <button
                                      onClick={calculateDistance}
                                      style={{ width: "fit-content" }}
                                      className="my-3 estimate_button mr-2  "
                                    >
                                      Estimate
                                    </button>
                                    <span style={{ fontWeight: "600" }}>
                                      {" "}
                                      {calLoading
                                        ? distanceResult
                                        : "Calculating..."}
                                    </span>
                                  </span>
                                </div>
                                <p
                                  style={{
                                    fontSize: "10px",
                                  }}
                                >
                                  {" "}
                                  <span style={{ fontWeight: "600" }}>
                                    Note :{" "}
                                  </span>
                                  The prices provided herein are estimates and
                                  should be considered as such. They do not
                                  necessarily reflect the final or original
                                  prices of the products or services mentioned
                                </p>
                              </div>
                              <iframe
                                id="map-iframe"
                                // width="auto"
                                style={{ width: "100%" }}
                                height="400"
                                className="d-md-none mt-3"
                                frameborder="0"
                                src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyDrmfNuPxqcip7_nE3dVNas9d3bNgNivlQ&origin=${
                                  origin || "Hyderabad"
                                }&destination=${destination || "Hyderabad"}`}
                              ></iframe>
                            </div>
                          </div>
                        </div>
                        <div
                          id="tab4"
                          className={`tab ${
                            activeTab === "tab4" ? "active" : ""
                          }`}
                        >
                          <div className="row">
                            <div className="col-1"></div>
                            <div className="col-lg-10 tabs_content_text">
                              <h2>Estimate Here</h2>

                              <div>
                                <div className="">
                                  <PlacesAutocomplete
                                    value={origin}
                                    onChange={setOrigin}
                                    onSelect={handleOriginSelect}
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading,
                                    }) => (
                                      <div
                                        style={{ position: "relative" }}
                                        className="w-100"
                                      >
                                        <input
                                          required
                                          {...getInputProps({
                                            placeholder: "From",
                                            className: "form-control mt-3",
                                            onKeyPress: handleKeyPress,
                                          })}
                                        />
                                        <div
                                          className="suggestion-container"
                                          style={{
                                            display: suggestions.length
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          {loading && (
                                            <div className="text-center">
                                              Loading...
                                            </div>
                                          )}
                                          {suggestions.map((suggestion) => (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className: "suggestion-item",
                                                }
                                              )}
                                            >
                                              {suggestion.description}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>

                                  <PlacesAutocomplete
                                    value={destination}
                                    onChange={setDestination}
                                    onSelect={handleDestinationSelect}
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading,
                                    }) => (
                                      <div
                                        style={{ position: "relative" }}
                                        className="w-100 "
                                      >
                                        <input
                                          required
                                          {...getInputProps({
                                            placeholder: "To",
                                            className: "form-control mt-3",
                                            onKeyPress: handleKeyPress,
                                          })}
                                        />
                                        <div
                                          className="suggestion-container"
                                          style={{
                                            display: suggestions.length
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map((suggestion) => (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className: "suggestion-item",
                                                }
                                              )}
                                            >
                                              {suggestion.description}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                                </div>
                                <div>
                                  <span>
                                    <button
                                      onClick={calculateDistance}
                                      style={{ width: "fit-content" }}
                                      className="my-3 estimate_button mr-2  "
                                    >
                                      Estimate
                                    </button>
                                    <span style={{ fontWeight: "600" }}>
                                      {" "}
                                      {calLoading
                                        ? distanceResult
                                        : "Calculating..."}
                                    </span>
                                  </span>
                                </div>
                                <p
                                  style={{
                                    fontSize: "10px",
                                  }}
                                >
                                  {" "}
                                  <span style={{ fontWeight: "600" }}>
                                    Note :{" "}
                                  </span>
                                  The prices provided herein are estimates and
                                  should be considered as such. They do not
                                  necessarily reflect the final or original
                                  prices of the products or services mentioned
                                </p>
                              </div>
                              <iframe
                                id="map-iframe"
                                // width="auto"
                                style={{ width: "100%" }}
                                height="400"
                                className="d-md-none mt-3"
                                frameborder="0"
                                src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyDrmfNuPxqcip7_nE3dVNas9d3bNgNivlQ&origin=${
                                  origin || "Hyderabad"
                                }&destination=${destination || "Hyderabad"}`}
                              ></iframe>
                            </div>
                            {/* <div className="col-lg-4 container individual_iamge d-flex flex-lg-column-none flex-column justify-content-center align-items-center">
                        <iframe
                          id="map-iframe"
                          // width="auto"
                          style={{ width: "100%" }}
                          height="450"
                          frameborder="0"
                          src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyDrmfNuPxqcip7_nE3dVNas9d3bNgNivlQ&origin=${
                            origin || "Hyderabad"
                          }&destination=${destination || "Hyderabad"}`}
                        ></iframe>
                        <div>
                          <img
                            src={pts}
                            alt=""
                            className="d-lg-none"
                            style={{ paddingTop: "30px" }}
                          />
                        </div>
                      </div> */}
                          </div>
                          {/* <div className="row">
                    <div className="col-lg-8 tabs_content_text">
                      <h2>Patient Transport Service</h2>

                      <PlacesAutocomplete
                        value={origin}
                        onChange={setOrigin}
                        onSelect={handleOriginSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div style={{ position: "relative" }}>
                            <input
                              required
                              {...getInputProps({
                                placeholder: "From",
                                className: "form-control mt-3",
                                onKeyPress: handleKeyPress,
                              })}
                            />
                            <div
                              className="suggestion-container"
                              style={{
                                display: suggestions.length ? "block" : "none",
                              }}
                            >
                              {loading && (
                                <div className="text-center">Loading...</div>
                              )}
                              {suggestions.map((suggestion) => (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className: "suggestion-item",
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>

                      <PlacesAutocomplete
                        value={destination}
                        onChange={setDestination}
                        onSelect={handleDestinationSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div style={{ position: "relative" }}>
                            <input
                              required
                              {...getInputProps({
                                placeholder: "To",
                                className: "form-control mt-3",
                                onKeyPress: handleKeyPress,
                              })}
                            />
                            <div
                              className="suggestion-container"
                              style={{
                                display: suggestions.length ? "block" : "none",
                              }}
                            >
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className: "suggestion-item",
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>

                      <span>
                        <button
                          onClick={calculateDistance}
                          style={{ width: "fit-content" }}
                          className="my-3 estimate_button mr-2  "
                        >
                          Estimate
                        </button>
                        {calLoading ? distanceResult : "Calculating..."}
                      </span>
                    </div>
                    <div className="col-lg-4 individual_iamge d-flex justify-content-center align-items-center">
                      <img src={pts} alt="" />
                    </div>
                  </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5  d-none d-md-block d-md-flex flex-column">
                  <div data-aos="fade-down" data-aos-duration="2000">
                    <iframe
                      id="map-iframe"
                      // width="auto"
                      style={{ width: "100%" }}
                      height="400"
                      frameborder="0"
                      src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyDrmfNuPxqcip7_nE3dVNas9d3bNgNivlQ&origin=${
                        origin || "Hyderabad"
                      }&destination=${destination || "Hyderabad"}`}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="fluid-container thirdMainDiv">
        <div className="text-center ">
          <h2 className="thirdMainDivheading mt-5 mb-0">
            The Services We Offer
          </h2>
        </div>
      </div>

      <div className="worko-tabs">
        <input
          className="state"
          type="radio"
          title="tab-one"
          name="tabs-state"
          id="tab-one"
          checked={selectedTab === "tab-one"}
          onChange={() => handleTabClick1("tab-one")}
        />
        <input
          className="state"
          type="radio"
          title="tab-two"
          name="tabs-state"
          id="tab-two"
          checked={selectedTab === "tab-two"}
          onChange={() => handleTabClick1("tab-two")}
        />
        <input
          className="state"
          type="radio"
          title="tab-three"
          name="tabs-state"
          id="tab-three"
          checked={selectedTab === "tab-three"}
          onChange={() => handleTabClick1("tab-three")}
        />
        <input
          className="state"
          type="radio"
          title="tab-four"
          name="tabs-state"
          id="tab-four"
          checked={selectedTab === "tab-four"}
          onChange={() => handleTabClick1("tab-four")}
        />
        <input
          className="state"
          type="radio"
          title="tab-five"
          name="tabs-state"
          id="tab-five"
          checked={selectedTab === "tab-five"}
          onChange={() => handleTabClick1("tab-five")}
        />

        <div className="tabs flex-tabs">
          <label htmlFor="tab-one" id="tab-one-label" className="tab">
            Ventilator Ambulances
          </label>
          <label htmlFor="tab-two" id="tab-two-label" className="tab">
            BLS (Oxygen) Ambulance
          </label>
          <label htmlFor="tab-three" id="tab-three-label" className="tab">
            AIR Ambulance
          </label>
          <label htmlFor="tab-four" id="tab-four-label" className="tab">
            Freezer Box
          </label>
          <label htmlFor="tab-five" id="tab-five-label" className="tab">
            Embalming
          </label>

          <div
            id="tab-one-panel"
            className={`panel ${selectedTab === "tab-one" ? "active" : ""}`}
          >
            <div className="row">
              <div className="col-1"></div>
              <div className="col-md-5">
                <img
                  src={ventilation}
                  className="w-100 my-5"
                  alt="Ventilator services"
                />
              </div>
              <div className="col-md-6 services_tabs d-flex justify-content-center flex-column">
                <h5>We offer ICU Ventilator services</h5>
                <h3>ICU Ventilator Ambulance Services</h3>
                <p>
                  Having dependable and prompt ICU Ventilator ambulance services
                  is essential during medical emergencies when every second
                  counts
                </p>
                <Link to="/ventilator-ambulance">
                  {" "}
                  <button
                    style={{ width: "fit-content" }}
                    className="my-3 estimate_button mr-2 p-3"
                  >
                    Read More <i class="ri-arrow-right-line"></i>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            id="tab-two-panel"
            className={`panel ${selectedTab === "tab-two" ? "active" : ""}`}
          >
            <div className="row">
              <div className="col-1"></div>
              <div className="col-md-5">
                <img
                  src={blsoxygen}
                  className="w-100 my-5"
                  alt="Ventilator services"
                />
              </div>
              <div className="col-md-6 services_tabs d-flex justify-content-center flex-column">
                <h5>We offer BLS oxygen Ambulance</h5>
                <h3>BLS Oxygen Ambulance Services</h3>
                <p>
                  BLS ambulance outfitted with essential medical equipment and
                  oxygen supply for emergency patient care and transport
                </p>
                <Link to="/BLS-ambulance">
                  {" "}
                  <button
                    style={{ width: "fit-content" }}
                    className="my-3 estimate_button mr-2 p-3"
                  >
                    Read More <i class="ri-arrow-right-line"></i>
                  </button>
                </Link>
              </div>
            </div>{" "}
          </div>
          <div
            id="tab-three-panel"
            className={`panel ${selectedTab === "tab-three" ? "active" : ""}`}
          >
            <div className="row">
              <div className="col-1"></div>
              <div className="col-md-5">
                <img
                  src={airAmbulance}
                  className="w-100 my-5"
                  alt="Ventilator services"
                />
              </div>
              <div className="col-md-6 services_tabs d-flex justify-content-center flex-column">
                <h5>We offer Aerial Ambulance services</h5>
                <h3>AIR Ambulance Services</h3>
                <p>
                  Air ambulance service providing essential oxygen support for
                  critical care patients during aerial medical transport
                </p>
                <Link to="/Air-ambulance">
                  {" "}
                  <button
                    style={{ width: "fit-content" }}
                    className="my-3 estimate_button mr-2 p-3"
                  >
                    Read More <i class="ri-arrow-right-line"></i>
                  </button>
                </Link>
              </div>
            </div>{" "}
          </div>
          <div
            id="tab-four-panel"
            className={`panel ${selectedTab === "tab-four" ? "active" : ""}`}
          >
            <div className="row">
              <div className="col-1"></div>
              <div className="col-md-5">
                <img
                  src={freezerbox}
                  className="w-100 my-5"
                  alt="Ventilator services"
                />
              </div>
              <div className="col-md-6 services_tabs d-flex justify-content-center flex-column">
                <h5>We offer Freezer Box services</h5>
                <h3>Freezer Box Ambulance Services</h3>
                <p>
                  Facilitating freezer box services for the dignified and
                  respectful transportation of deceased individuals with care.
                </p>
                <Link to="/freezer-box">
                  {" "}
                  <button
                    style={{ width: "fit-content" }}
                    className="my-3 estimate_button mr-2 p-3"
                  >
                    Read More <i class="ri-arrow-right-line"></i>
                  </button>
                </Link>
              </div>
            </div>{" "}
          </div>
          <div
            id="tab-five-panel"
            className={`panel ${selectedTab === "tab-five" ? "active" : ""}`}
          >
            <div className="row">
              <div className="col-1"></div>
              <div className="col-md-5">
                <img
                  src={emblam}
                  className="w-100 my-5"
                  alt="Ventilator services"
                />
              </div>
              <div className="col-md-6 services_tabs d-flex justify-content-center flex-column">
                <h5>We offer Embalming services</h5>
                <h3>Expert Embalming Services</h3>
                <p>
                  The procedure of embalming a deceased person is careful and
                  courteous, guaranteeing that the body will be preserved in a
                  respectable manner until the burial or viewing
                </p>
                <Link to="/Embalming">
                  {" "}
                  <button
                    style={{ width: "fit-content" }}
                    className="my-3 estimate_button mr-2 p-3"
                  >
                    Read More <i class="ri-arrow-right-line"></i>
                  </button>
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>

      <section>
        <div className="container mt-5">
          <div className="text-center mb-5">
            <h2 className="technologiesHeading ">Get In Touch ?</h2>
          </div>

          <div className="row">
            <div className="col-md-10">
              <div className="container">
                <div className="row">
                  <div className="col-md-7 mb-2">
                    <div data-aos="fade-down" data-aos-duration="2000">
                      <div className="d-flex justify-content-center my-2">
                        <img
                          src="https://img.freepik.com/free-photo/doctor-making-phone-call-checking-time_23-2147822688.jpg?size=626&ext=jpg&ga=GA1.1.1152151187.1702462035&semt=ais"
                          alt="shhbl"
                          className="handimg"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5 my-4">
                    <div data-aos="fade-up" data-aos-duration="2000">
                      <p className="contactText">
                        When seconds matter, choose Rapid ambulance. Call us for
                        immediate assistance. Your health is our priority, and
                        we're here for you 24/7
                      </p>
                      {/* <h4 className="conHead ">Follow Us on</h4>
                      <div
                        data-aos="fade-left"
                        data-aos-anchor="#example-anchor"
                        data-aos-offset="500"
                        data-aos-duration="500"
                      >
                        <div className="mt-3 mb-3">
                          <i className="fa-brands fa-linkedin-in linked "></i>
                          <i className="fa-brands fa-facebook-f face ml-2"></i>
                          <i className="fa-brands fa-instagram insta ml-2"></i>
                        </div>
                      </div> */}
                      <p className="conPhn mt-4">
                        <button
                          onClick={() =>
                            (window.location.href = "tel:+919030820612")
                          }
                          className="red_button"
                        >
                          <i className="fa-solid fa-phone-volume"></i> Call now
                        </button>
                        <a
                          href="https://wa.me/919030820612"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="ml-2 green_button ">
                            <i class="fa-brands fa-whatsapp "></i> Chat with us
                          </button>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="floating-icons">
        <a href="tel:+919030820612" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919030820612")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i>+91 9030820612
          </button>
        </a>
        <a href="https://wa.me/+919030820612" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>

      <div data-aos="fade-in" data-aos-duration="1500">
        <div className="footerSection ">
          <div className="container">
            <div className="row  firstFooterContainer ">
              <div className="firstCol  col-12">
                <div>
                  <img
                    src={footerLogo}
                    className="pabTopLogo"
                    id="bottomPabLogo"
                    alt=""
                  />
                  <p className="footerText">
                    Ambulance Service Hyderabad, ICU & Oxygen Ambulances,
                    Patient & Dead Body Transportation From Hyderabad To All
                    Over India. Local & Outstation Ambulances From Hyderabad.
                    Neat & Sanitized Ambulance. 24/7 Ambulance Support.
                  </p>
                </div>
              </div>

              <div className="thirdCol  col-12 ">
                <h4 className="text-center">Services </h4>

                <div className="d-flex flex-wrap justify-content-around">
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/BLS-ambulance">
                      <span>Basic Life Support</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/ventilator-ambulance">
                      <span>Ventilator Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/Air-ambulance">
                      <span>Air Ambulance Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/freezer-box">
                      <span> Freezer Box Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/Embalming">
                      <span>Embalming</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="thick-horizontal-line mx-2 "
              style={{ height: "1px", backgroundColor: "white" }}
            ></div>

            <div className="container secondFooterContainer row ">
              <div className="text-white col-md-6  col-12 align-self-center mt-4">
                <i className="fa-solid fa-copyright"></i> Copyright RAPID
                Ambulance. All rights reserved.
              </div>

              <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
                <i
                  className="fa-brands fa-linkedin mx-2 logos"
                  id="linkedIn"
                ></i>
                <i
                  className="fa-brands fa-instagram mx-2 logos"
                  id="instagram"
                ></i>
                <i
                  className="fa-brands fa-facebook mx-2 logos"
                  id="facebook"
                ></i>
                <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <script src="https://cdn.jsdelivr.net/npm/micro-slider@1.0.9/dist/micro-slider.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/hammer.js/2.0.8/hammer.min.js"></script>
    <script src="./index.js"></script>
    <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>
    <script>
      AOS.init();
    </script> */}
    </div>
  );
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyDrmfNuPxqcip7_nE3dVNas9d3bNgNivlQ",
})(App);
