import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import footerLogo from "../images/Swift Care White.png";

const BlogCards = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);

  return (
    <div style={{ marginTop: "80px" }}>
      <div className="floating-icons">
        <a href="tel:+919030820612" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919030820612")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i> +91 9030820612
          </button>
        </a>
        <a href="https://wa.me/+919030820612" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>

      <div className="Blogs_bg">
        <h1>Hyderabad's Top Ambulance Service - Rapid Ambulance</h1>
      </div>

      <div className="container blogs_container">
        <div className="mt-5 mb-5 text-center">
          <h2>Will Alter Your Perspective On Medical Blogs.</h2>
        </div>

        <div className="card mb-5">
          <h4>Best Ambulance Service in Hyderabad</h4>
          <div className="d-flex">
            <p>
              <i class="ri-user-3-fill"></i>admin
            </p>
            <p>
              {" "}
              <i class="ri-time-fill"></i>22 Aug 2024
            </p>
          </div>
          <p className="truncate1">
            If you’re looking for the best ambulance service in Hyderabad?, look
            no further.
          </p>
          <p className="truncate">
            Call us (9030820612). We are the best ambulance service providers
            from hyderabad to any city in india. Our fully equipped ambulances
            in Hyderabad are designed to provide prompt medical help when you
            need it most. With 24/7 services available, you can rest assured
            that the nearest ambulance is always ready to assist you. Whether
            it’s a medical emergency or non-urgent transport, our dedicated team
            is committed to delivering the highest quality care and support,
            ensuring a safe journey to the hospital. Trust us for reliable and
            rapid ambulance services near Hyderabad, because your health is our
            priority.
          </p>
          <Link to="/rapid-blog1">
            <button>Read More</button>
          </Link>
        </div>

        <div className="card mb-5">
          <h4>Dead Body Ambulance Service Hyderabad: 9030820612</h4>
          <div className="d-flex">
            <p>
              <i class="ri-user-3-fill"></i>admin
            </p>
            <p>
              {" "}
              <i class="ri-time-fill"></i>22 Aug 2024
            </p>
          </div>
          <p className="truncate1">
            Call Us (9030820612) In times of loss, arranging for the
            transportation of a deceased loved one can be an emotionally
            challenging task.
          </p>
          <p className="truncate">
            At Rapid Ambulance Service, we provide a compassionate and
            professional Dead Body Ambulance Service in Hyderabad, ensuring that
            the transportation process is handled with the utmost respect and
            care.
          </p>
          <Link to="/rapid-blog2">
            <button>Read More</button>
          </Link>
        </div>

        <div className="card mb-5">
          <h4>Ambulance Service Near You Hyderabad</h4>
          <div className="d-flex">
            <p>
              <i class="ri-user-3-fill"></i>admin
            </p>
            <p>
              {" "}
              <i class="ri-time-fill"></i>22 Aug 2024
            </p>
          </div>

          <p className="truncate">
            Call (9030820612) In times of medical emergencies, quick and
            reliable ambulance service can be a lifesaver. If you’re searching
            for an ambulance service near you in Hyderabad, look no further than
            Rapid Ambulance Service. We are proud to be recognized as one of the
            top and best ambulance service providers in the city, offering a
            comprehensive range of services at low cost.
          </p>
          <Link to="/rapid-blog3">
            <button>Read More</button>
          </Link>
        </div>

        <div className="card mb-5">
          <h4>Best Private Ambulance Service Hyderabad</h4>
          <div className="d-flex">
            <p>
              <i class="ri-user-3-fill"></i>admin
            </p>
            <p>
              {" "}
              <i class="ri-time-fill"></i>22 Aug 2024
            </p>
          </div>
          <p className="truncate1">
            Call 9030820612 In critical moments when health and safety are on
            the line, having access to a reliable private ambulance service
            hyderabad can make all the difference.
          </p>
          <p className="truncate">
            At Rapid Ambulance Service Hyderabad, we provide a comprehensive
            range of medical transportation options, ensuring that you and your
            loved ones receive the care you need when it matters most.
          </p>
          <Link to="/rapid-blog4">
            <button>Read More</button>
          </Link>
        </div>
      </div>

      <div className="footerSection ">
        <div className="container">
          <div className="row  firstFooterContainer ">
            <div className="firstCol  col-12">
              <div>
                <img
                  src={footerLogo}
                  className="pabTopLogo"
                  id="bottomPabLogo"
                  alt=""
                />
                <p className="footerText">
                  Our commitment to expert precision shines through, guiding you
                  from moments of emergency to a realm of dedicated care. Our
                  ambulance services embody the essence of Rapidness, ensuring
                  that with just a call, essential care is at your fingertips.
                  Recognizing that every second holds profound significance, we
                  unleash the power of urgent, professional care, understanding
                  that your well-being hinges on rapid intervention.
                </p>
              </div>
            </div>

            <div className="thirdCol  col-12 ">
              <h4 className="text-center">Services </h4>

              <div className="d-flex flex-wrap justify-content-around">
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/BLS-ambulance">
                    <span>Basic Life Support</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/ventilator-ambulance">
                    <span>Ventilator Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Air-ambulance">
                    <span>Air Ambulance Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/freezer-box">
                    <span> Freezer Box Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Embalming">
                    <span>Embalming</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            className="thick-horizontal-line mx-2 "
            style={{ height: "1px", backgroundColor: "white" }}
          ></div>

          <div className="container secondFooterContainer row ">
            <div className="text-white col-md-6  col-12 align-self-center mt-4">
              <i className="fa-solid fa-copyright"></i> Copyright RAPID
              Ambulance. All rights reserved.
            </div>

            <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
              <i className="fa-brands fa-linkedin mx-2 logos" id="linkedIn"></i>
              <i
                className="fa-brands fa-instagram mx-2 logos"
                id="instagram"
              ></i>
              <i className="fa-brands fa-facebook mx-2 logos" id="facebook"></i>
              <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCards;
