import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../images/Swift Care White.png";

const Blog2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);
  return (
    <div style={{ marginTop: "80px" }}>
      <div className="floating-icons">
        <a href="tel:+919030820612" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919030820612")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i> +91 9030820612
          </button>
        </a>
        <a href="https://wa.me/+919030820612" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>

      <div className="Blogs_bg1">
        <h1>Revolutionize Your Approach to Medical Equipment & Services</h1>
      </div>

      <div className="container blogs_container1 mb-5">
        <h3>Dead Body Ambulance Service Hyderabad: 9030820612</h3>
        <div className="d-flex">
          <p>
            <i class="ri-user-3-fill"></i>admin
          </p>
          <p>
            {" "}
            <i class="ri-calendar-schedule-fill"></i>22 Aug 2024
          </p>
        </div>

        <p>
          Call Us{" "}
          <a
            href="tel:+919030820612"
            onClick={() => (window.location.href = "tel:+919030820612")}
          >
            +91 9030820612
          </a>{" "}
          In times of loss, arranging for the transportation of a deceased loved
          one can be an emotionally challenging task.
        </p>

        <p>
          At Rapid Ambulance Service, we provide a compassionate and
          professional Dead Body Ambulance Service in Hyderabad, ensuring that
          the transportation process is handled with the utmost respect and
          care.
        </p>
        <p>
          Rapid Ambulance Service provides the most dependable ambulance
          transportation service in Hyderabad, equipped with modern transport
          ventilators, heart monitors, and life-saving medications to ensure
          patients receive critical care on the way to the hospital. Our
          services are available at very affordable prices. Utilizing GPS
          technology, we efficiently monitor and reach your location in the
          shortest time possible, ensuring swift and reliable assistance when
          you need it most.
        </p>

        <h5>Comprehensive Transportation Options</h5>

        <h6>Road and Air Transportation</h6>
        <p>
          We specialize in dead body transportation by both road and air,
          facilitating the transfer of your loved ones to any city across India
          from Hyderabad. Our team ensures that all logistical arrangements are
          managed efficiently, allowing you to focus on honoring your loved
          one’s memory.
        </p>

        <h6>Dead Body Freezer Ambulance</h6>
        <p>
          To preserve the dignity of the deceased, we offer specialized dead
          body freezer ambulances in Hyderabad. These vehicles are equipped with
          refrigeration units to maintain the appropriate temperature,
          preventing decomposition during transportation. This is especially
          important for longer journeys.
        </p>

        <h6> Clean and Sanitized Vehicles</h6>
        <p>
          Our fleet of ambulances is maintained to the highest standards of
          cleanliness and sanitation. We understand the sensitivity surrounding
          these situations and ensure that our vehicles are neat, sanitized, and
          equipped with all necessary facilities for a respectful transfer.
        </p>

        <h6>Affordable and Transparent Pricing</h6>
        <p>
          We believe that high-quality service should be accessible, even during
          difficult times. Our low-cost dead body transportation services are
          designed to provide you with a respectful and dignified option without
          putting a financial strain on your family. We maintain a transparent
          pricing structure, so there are no hidden fees.
        </p>

        <h6>Best Dead Body Ambulances in Hyderabad</h6>
        <p>
          At Rapid Ambulance Service, we take pride in being one of the best
          providers of dead body ambulances in Hyderabad. Our dedicated team is
          trained to handle every aspect of the process with sensitivity and
          professionalism, ensuring a smooth and respectful experience for
          grieving families.
        </p>

        <h6>Contact Us</h6>
        <p>
          If you need a reliable dead body ambulance service, don’t hesitate to
          reach out to us at **9030820612**. We are available 24/7 to assist you
          with all your transportation needs, providing you with the support and
          care you deserve during this difficult time.
        </p>
        <p>
          Let us help you honor your loved one’s memory with the dignity and
          respect they deserve.
        </p>
      </div>

      <div className="footerSection ">
        <div className="container">
          <div className="row  firstFooterContainer ">
            <div className="firstCol  col-12">
              <div>
                <img
                  src={footerLogo}
                  className="pabTopLogo"
                  id="bottomPabLogo"
                  alt=""
                />
                <p className="footerText">
                  Our commitment to expert precision shines through, guiding you
                  from moments of emergency to a realm of dedicated care. Our
                  ambulance services embody the essence of Rapidness, ensuring
                  that with just a call, essential care is at your fingertips.
                  Recognizing that every second holds profound significance, we
                  unleash the power of urgent, professional care, understanding
                  that your well-being hinges on rapid intervention.
                </p>
              </div>
            </div>

            <div className="thirdCol  col-12 ">
              <h4 className="text-center">Services </h4>

              <div className="d-flex flex-wrap justify-content-around">
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/BLS-ambulance">
                    <span>Basic Life Support</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/ventilator-ambulance">
                    <span>Ventilator Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Air-ambulance">
                    <span>Air Ambulance Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/freezer-box">
                    <span> Freezer Box Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Embalming">
                    <span>Embalming</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            className="thick-horizontal-line mx-2 "
            style={{ height: "1px", backgroundColor: "white" }}
          ></div>

          <div className="container secondFooterContainer row ">
            <div className="text-white col-md-6  col-12 align-self-center mt-4">
              <i className="fa-solid fa-copyright"></i> Copyright RAPID
              Ambulance. All rights reserved.
            </div>

            <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
              <i className="fa-brands fa-linkedin mx-2 logos" id="linkedIn"></i>
              <i
                className="fa-brands fa-instagram mx-2 logos"
                id="instagram"
              ></i>
              <i className="fa-brands fa-facebook mx-2 logos" id="facebook"></i>
              <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog2;
