import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../images/Swift Care White.png";

const Blog1 = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
      }, []);
  return (
    <div style={{ marginTop: "80px" }}>
      <div className="floating-icons">
        <a href="tel:+919030820612" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919030820612")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i> +91 9030820612
          </button>
        </a>
        <a href="https://wa.me/+919030820612" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>

      <div className="Blogs_bg1">
        <h1>Revolutionize Your Approach to Medical Equipment & Services</h1>
      </div>

      <div className="container blogs_container1 mb-5">
        <h3>Best Ambulance Service in Hyderabad</h3>
        <div className="d-flex">
            <p>
              <i class="ri-user-3-fill"></i>admin
            </p>
            <p>
              {" "}
              <i class="ri-calendar-schedule-fill"></i>22 Aug 2024
            </p>
          </div>


        <p>
          If you’re looking for the best ambulance service in Hyderabad?, look
          no further.
        </p>

        <p>
          Call us{" "}
          <a
            href="tel:+919030820612"
            onClick={() => (window.location.href = "tel:+919030820612")}
          >
            +91 9030820612
          </a>
          . We are the best ambulance service providers from hyderabad to any
          city in india. Our fully equipped ambulances in Hyderabad are designed
          to provide prompt medical help when you need it most. With 24/7
          services available, you can rest assured that the nearest ambulance is
          always ready to assist you. Whether it’s a medical emergency or
          non-urgent transport, our dedicated team is committed to delivering
          the highest quality care and support, ensuring a safe journey to the
          hospital. Trust us for reliable and rapid ambulance services near
          Hyderabad, because your health is our priority.
        </p>
        <p>
          Rapid Ambulance Service provides the most dependable ambulance
          transportation service in Hyderabad, equipped with modern transport
          ventilators, heart monitors, and life-saving medications to ensure
          patients receive critical care on the way to the hospital. Our
          services are available at very affordable prices. Utilizing GPS
          technology, we efficiently monitor and reach your location in the
          shortest time possible, ensuring swift and reliable assistance when
          you need it most.
        </p>

        <h5>Our Mission: Committed to Patient Health</h5>
        <p>
          At Rapid Ambulance Service Hyderabad, we firmly believe that timely
          medical intervention can save lives. Our services are designed under
          the expert guidance of medical specialists who provide essential first
          aid and pre-hospital care as we transport patients to the hospital.
          Your health is our top priority, and we strive to ensure every patient
          receives the best possible care from the moment they step into our
          ambulance.
        </p>

        <h5>Types of Ambulance Services :</h5>

        <h6>Basic Life Support Ambulance (BLS)</h6>
        <p>
          Designed for patients who do not require advanced medical care or
          heart monitoring before reaching the hospital. BLS is suitable for
          less critical situations, such as simple fractures or routine
          discharges. These ambulances come equipped with basic medical
          equipment, including pulse oximeters, BP monitors, oxygen machines,
          and essential medications.
        </p>

        <h6>Advanced Life Support Ambulance (Ventilator Ambulance)</h6>
        <p>
          This service is for patients needing medical assistance during
          emergencies until they arrive at the hospital. ALS teams consist of
          paramedics and EMTs equipped with advanced medical tools, such as
          oxygen cylinders, ventilators, defibrillators, ECG devices, and heart
          monitors. ALS technicians are trained to administer injections and
          pain relief medications.
        </p>

        <h6>ICU Ambulance Service Hyderabad</h6>
        <p>
          Specially designed for critically ill patients who require intensive
          care during transport from one hospital to another. A dedicated team
          of emergency nurses and a doctor provide immediate medical attention
          for any emergencies. ICU ambulances are equipped with the latest
          ventilators for continuous oxygen therapy, heart monitors,
          defibrillators, syringe pumps, nebulizers, and emergency medications.
        </p>

        <h6>Mortuary Ambulance Service</h6>
        <p>
          This service is used for the transportation of deceased individuals.
          Some mortuary ambulances are equipped with freezers to help preserve
          the body’s organs and prevent decomposition.
        </p>

        <h6>Air Ambulance Service</h6>
        <p>
          Ideal for transferring patients with serious illnesses over longer
          distances during emergencies. These ambulances are equipped with the
          latest medical technology to ensure patient safety and are staffed by
          qualified emergency medical professionals and paramedics. Air
          ambulances are also utilized for organ transplants from donors in
          remote locations.
        </p>

        <h6>Patient Transfer Ambulance Service Hyderabad</h6>
        <p>
          Our Patient Transfer Ambulance Service is designed to transport
          individuals to dialysis or hospital centers that require medical
          monitoring, but not urgent treatment. This service is especially
          beneficial for patients with disabilities who need wheelchairs or
          those unable to travel by other means.
        </p>
        <p>
          At Rapid Ambulance Hyderabad, we are dedicated to providing
          comprehensive ambulance services, ensuring prompt and efficient
          medical care whenever it’s needed. Your health and comfort are our top
          priorities during every transfer.
        </p>
      </div>

      <div className="footerSection ">
        <div className="container">
          <div className="row  firstFooterContainer ">
            <div className="firstCol  col-12">
              <div>
                <img
                  src={footerLogo}
                  className="pabTopLogo"
                  id="bottomPabLogo"
                  alt=""
                />
                <p className="footerText">
                  Our commitment to expert precision shines through, guiding you
                  from moments of emergency to a realm of dedicated care. Our
                  ambulance services embody the essence of Rapidness, ensuring
                  that with just a call, essential care is at your fingertips.
                  Recognizing that every second holds profound significance, we
                  unleash the power of urgent, professional care, understanding
                  that your well-being hinges on rapid intervention.
                </p>
              </div>
            </div>

            <div className="thirdCol  col-12 ">
              <h4 className="text-center">Services </h4>

              <div className="d-flex flex-wrap justify-content-around">
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/BLS-ambulance">
                    <span>Basic Life Support</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/ventilator-ambulance">
                    <span>Ventilator Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Air-ambulance">
                    <span>Air Ambulance Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/freezer-box">
                    <span> Freezer Box Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Embalming">
                    <span>Embalming</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            className="thick-horizontal-line mx-2 "
            style={{ height: "1px", backgroundColor: "white" }}
          ></div>

          <div className="container secondFooterContainer row ">
            <div className="text-white col-md-6  col-12 align-self-center mt-4">
              <i className="fa-solid fa-copyright"></i> Copyright RAPID
              Ambulance. All rights reserved.
            </div>

            <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
              <i className="fa-brands fa-linkedin mx-2 logos" id="linkedIn"></i>
              <i
                className="fa-brands fa-instagram mx-2 logos"
                id="instagram"
              ></i>
              <i className="fa-brands fa-facebook mx-2 logos" id="facebook"></i>
              <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog1;
