import React, { useState, useEffect } from "react";
import logo from "../images/Rapid Ambulance.png";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed-top ${isScrolled ? "scrolled" : ""}`}>
      <div className="wrapper">
        <div className="logo">
          <Link to="/" onClick={closeMenu}>
            <img src={logo} alt="" />
          </Link>
        </div>
        <input
          type="checkbox"
          id="menu-btn"
          checked={isOpen}
          onChange={toggleMenu}
        />
        <input
          type="checkbox"
          id="close-btn"
          checked={!isOpen}
          onChange={toggleMenu}
        />
        <ul className={`nav-links ml-auto mb-0${isOpen ? " open" : ""}`}>
          <label htmlFor="close-btn" className="btn close-btn">
            <i className="ri-menu-3-line" style={{ color: "#ff1f27" }}></i>
          </label>

          <li>
            <Link to="/" style={{ color: "#ff1f27" }} onClick={closeMenu}>
              Home
            </Link>
          </li>

          <li>
            <Link className="desktop-item" style={{ color: "#ff1f27" }}>
              Services <i className="ri-arrow-down-s-fill"></i>
            </Link>
            <input type="checkbox" id="showDrop3" />
            <label
              htmlFor="showDrop3"
              className="mobile-item"
              style={{ color: "#ff1f27" }}
            >
              Services <i className="ri-arrow-down-s-fill"></i>
            </label>
            <ul className="drop-menu">
              <li>
                <Link to="/ventilator-ambulance" onClick={closeMenu}>
                  Ventilator Ambulances
                </Link>
              </li>
              <li>
                <Link to="/BLS-ambulance" onClick={closeMenu}>
                  BLS (Oxygen) Ambulance
                </Link>
              </li>
              <li>
                <Link to="/freezer-box" onClick={closeMenu}>
                  Freezer Box Ambulance
                </Link>
              </li>
              <li>
                <Link to="/Air-ambulance" onClick={closeMenu}>
                  AIR Ambulance
                </Link>
              </li>
              <li>
                <Link to="/Embalming" onClick={closeMenu}>
                  Embalming
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/about" style={{ color: "#ff1f27" }} onClick={closeMenu}>
              About
            </Link>
          </li>

          <li>
            <Link to="/blog-cards" style={{ color: "#ff1f27" }} onClick={closeMenu}>
              Blog
            </Link>
          </li>


          <li>
            <Link
              to="/contact"
              style={{ color: "#ff1f27" }}
              onClick={closeMenu}
            >
              Contact
            </Link>
          </li>
          <li>
            <Link
              to="https://wa.me/+919030820612"
              className="green_fixed_button_nav"
            >
              <span style={{ color: "white" }}>Whatsapp</span>
            </Link>
          </li>
        </ul>
        <label htmlFor="menu-btn" className="btn menu-btn">
          <i className="ri-menu-3-line" style={{ color: "#ff1f27" }}></i>
        </label>
      </div>
    </nav>
  );
};

export default Navbar;
